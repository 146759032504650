import { useEffect } from 'react';

import { addToBasket } from '../utils';

export interface MessageEventData {
  trigger?: string;
  url?: string;
}

export const onMessage = ({ data }: MessageEvent<MessageEventData | undefined>) => {
  if (!data) {
    return;
  }
  const { trigger, url } = data;
  const productId = url ? new URL(url).searchParams.get('code') : undefined;
  if (productId && trigger === 'showProduct') {
    addToBasket({
      pageIndex: 0,
      productId,
      quantity: 1,
      title: '',
      originPage: 1,
      description: '',
      price: 0,
    });
  }
};

export const useDisplay = () => {
  useEffect(() => {
    window.addEventListener('message', onMessage, false);
  }, []);
};
