import type { FC, PropsWithChildren } from 'react';
import React, { createContext, useMemo } from 'react';

import { useToggle } from '../hooks/useToggle';
import type { OverviewContextValues } from '../models';

export const OverviewContext = createContext<OverviewContextValues | undefined>(undefined);
OverviewContext.displayName = 'OverviewContext';

export const OverviewProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isOverviewOpen, toggleOverviewOpen] = useToggle(false);

  const value = useMemo(
    () => ({
      isOverviewOpen,
      toggleOverviewOpen,
    }),
    [isOverviewOpen, toggleOverviewOpen],
  );

  return <OverviewContext.Provider value={value}>{children}</OverviewContext.Provider>;
};
