import { styled } from '@ori-ui/mui';
import useTestId from '@ori/testid-generator';
import { memo, useRef, type FC } from 'react';

import { IPaperWrapper } from '../../containers/IPaperWrapper';
import { useCatalogueUrlAndId } from '../../hooks/useCatalogueUrlAndId';
import { useDisplay } from '../../hooks/useDisplay';
import { useIPaperInit } from '../../hooks/useIPaperInit';
import { usePageNumberContext } from '../../hooks/usePageNumberContext';
import { IPAPER_IFRAME_ID } from '../../utils';
import { Loading } from '../Loading';
import { IPAPER } from './constants';

const Root = styled('iframe')({
  border: 'none',
  boxSizing: 'border-box',
  height: '100%',
  width: '100%',
});

export const IPaper: FC = memo(() => {
  const { initialPage } = usePageNumberContext();
  const { catalogueUrl, isLoading: isLoadingCatalogueUrl } = useCatalogueUrlAndId();
  const { getTestId } = useTestId();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const iframeLoaded = iframeRef.current !== null;

  // Initialize iPaper API
  useIPaperInit(iframeLoaded);
  useDisplay();

  // suffix "-brp" is needed to block eCatalogue basket on the page
  const fullCatalogueUrl = `${catalogueUrl}-brp?HideStandardUI=true&Page=${initialPage}`;

  return (
    <IPaperWrapper>
      {isLoadingCatalogueUrl || !catalogueUrl ? (
        <Loading />
      ) : (
        <Root
          ref={iframeRef}
          data-ipaper
          id={IPAPER_IFRAME_ID}
          data-testid={getTestId(IPAPER)}
          src={fullCatalogueUrl}
          title="iPaper"
        />
      )}
    </IPaperWrapper>
  );
});
