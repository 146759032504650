import { fetchGraphql } from '@ori/fetching';

import type { GetProductQuery, GetProductQueryVariables } from '../models';
import { productQuery } from '../queries-net';
import type { GetProductParams } from './types';

export const getProduct = async ({
  graphQlUrl,
  headers,
  productCode,
  token,
}: GetProductParams): Promise<GetProductQuery> => {
  if (!token) {
    return { product: null };
  }

  const url = new URL(graphQlUrl);
  url.pathname = '/graphql';

  const data = await fetchGraphql<GetProductQuery, GetProductQueryVariables>({
    query: productQuery,
    url: url.toString(),
    headers,
    variables: {
      productCode,
    },
  });

  return data;
};
