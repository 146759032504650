import { Typography } from '@ori-ui/mui';
import useTestId from '@ori/testid-generator';
import type { FC } from 'react';
import React, { useCallback } from 'react';

import { useOverviewContext } from '../../hooks/useOverviewContext';
import { getApiInstance } from '../../utils/getApiInstance';
import { ImageWithSkeleton } from '../ImageWithSkeleton';
import { OVERVIEW_BUTTON } from './constants';
import { Button, ImageWrapper, Item, PageNumber } from './styles';

export interface OverviewButtonProps {
  imageUrl: string;
  pageNumber: number;
}

export const OverviewButton: FC<OverviewButtonProps> = ({ imageUrl, pageNumber }) => {
  const { getTestId } = useTestId();
  const { toggleOverviewOpen } = useOverviewContext();
  const goToPage = useCallback(() => {
    toggleOverviewOpen();

    const ipaperApiInstance = getApiInstance();
    if (ipaperApiInstance) {
      ipaperApiInstance.paging.goToPage(pageNumber);
    }
  }, [pageNumber, toggleOverviewOpen]);

  return (
    <Item data-testid={getTestId(OVERVIEW_BUTTON)}>
      <Button
        type="button"
        onClick={goToPage}
      >
        <ImageWrapper>
          <ImageWithSkeleton src={imageUrl} />
        </ImageWrapper>
        <PageNumber>
          <Typography
            component="span"
            fontWeight="bold"
          >
            {pageNumber}
          </Typography>
        </PageNumber>
      </Button>
    </Item>
  );
};
