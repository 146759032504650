import useTestId from '@ori/testid-generator';
import type { FC } from 'react';
import React, { useEffect, useMemo, useRef } from 'react';

import { OVERVIEW_CONTENT_ELEMENT_ID } from '../../constants/variables';
import { useCatalogueUrlAndId } from '../../hooks/useCatalogueUrlAndId';
import { useOverviewContext } from '../../hooks/useOverviewContext';
import { usePageNumberContext } from '../../hooks/usePageNumberContext';
import { generateImageUrls, isDefined } from '../../utils';
import { OverviewButton } from '../OverviewButton';
import { Root } from './styles';

export const OverviewContent: FC = () => {
  const { catalogueUrl } = useCatalogueUrlAndId();
  const { isOverviewOpen } = useOverviewContext();
  const { totalPageCount } = usePageNumberContext();
  const { getTestId } = useTestId();
  const contentRef = useRef<HTMLUListElement>(null);

  const pageThumbnails = useMemo(
    () => (catalogueUrl ? generateImageUrls(catalogueUrl, totalPageCount, 'Normal') : []),
    [catalogueUrl, totalPageCount],
  );

  useEffect(() => {
    if (isOverviewOpen) {
      contentRef.current?.focus();
    }
  }, [isOverviewOpen]);

  if (!(isDefined(catalogueUrl) && pageThumbnails.length > 0)) {
    return null;
  }

  return (
    <Root
      ref={contentRef}
      data-testid={getTestId(OVERVIEW_CONTENT_ELEMENT_ID)}
      id={OVERVIEW_CONTENT_ELEMENT_ID}
      tabIndex={-1}
    >
      {pageThumbnails.map(({ imageUrl, pageNumber }) => (
        <OverviewButton
          key={imageUrl}
          imageUrl={imageUrl}
          pageNumber={pageNumber}
        />
      ))}
    </Root>
  );
};
