/* istanbul ignore file */
import { IPAPER_SCRIPT_ID, IPAPER_SCRIPT_URL } from './constants';

/**
 * Function that returns existing ipaper-script in <head> or creates a new one in <head> and returns it.
 * @returns script element or null
 */
export const loadIPaperAPIScript = () => {
  const existingScript = document.getElementById(IPAPER_SCRIPT_ID);
  // ipaper-script already exists in <head>, we can return it.
  if (existingScript) {
    return existingScript;
  }

  // ipaper-script does not exist in <head>, we need to create it and append it to <head>.
  const script = document.createElement('script');
  const head = document.querySelector('head');

  // <head> does not exist, we end here.
  if (!head) {
    return null;
  }
  // <head> exists, script is added and values are assigned to it.
  head.append(script);

  script.id = IPAPER_SCRIPT_ID;
  script.async = true;
  script.src = IPAPER_SCRIPT_URL;

  return script;
};
