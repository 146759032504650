import { CircularProgress, styled } from '@ori-ui/mui';
import React, { memo } from 'react';

const Root = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  textAlign: 'center',
});

export const Loading = memo(() => (
  <Root>
    <CircularProgress color="primary" />
  </Root>
));
