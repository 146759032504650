import { Typography } from '@ori-ui/mui';
import useTestId from '@ori/testid-generator';
import type { FC } from 'react';

import { useTranslations } from '../../hooks/useTranslations';
import { LATEST_CATALOGUES_DESCRIPTION } from './constants';
import { Inner, Root } from './styles';

export interface LatestCataloguesDescriptionProps {
  className?: string;
}

export const LatestCataloguesDescription: FC<LatestCataloguesDescriptionProps> = ({ className }) => {
  const { getTestId } = useTestId();
  const { translations } = useTranslations();

  return (
    <Root
      className={className}
      data-testid={getTestId(LATEST_CATALOGUES_DESCRIPTION)}
    >
      <Typography
        variant="h3"
        component="h3"
      >
        {translations.landingPageLatestCatalogueTitle}
      </Typography>
      <Inner>
        <Typography
          variant="subtitle1"
          component="h6"
        >
          {translations.landingPageLatestCatalogueSubtitle}
        </Typography>
        <Typography>{translations.landingPageLatestCatalogueDescription}</Typography>
      </Inner>
    </Root>
  );
};
