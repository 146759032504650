import { Skeleton, styled, TextField } from '@ori-ui/mui';
import useTestId from '@ori/testid-generator';
import type { FocusEvent, KeyboardEvent, FC } from 'react';
import React, { memo, useCallback, useMemo } from 'react';

import { ARIA_LABEL } from '../../constants';
import { useOverviewContext } from '../../hooks/useOverviewContext';
import { usePageNumberContext } from '../../hooks/usePageNumberContext';
import { useToggle } from '../../hooks/useToggle';
import { useTranslations } from '../../hooks/useTranslations';
import { PageCounter } from '../PageCounter';
import { NAVIGATION, NAVIGATION_SKELETON } from './constants';
import { getPageNumber } from './getPageNumber';

const Root = styled(TextField)(({ theme }) => ({
  width: '85px',

  '.page-navigator-input': {
    padding: theme.spacing(2),
    textAlign: 'center',

    '&::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },

    '&::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
}));

export const PageNavigator: FC = memo(() => {
  const [isInputMode, toggleInputMode] = useToggle(false);
  const { changePageNumber, currentPages, totalPageCount } = usePageNumberContext();
  const { getTestId } = useTestId();
  const { translations } = useTranslations();
  const { isOverviewOpen } = useOverviewContext();
  const pageNumber = useMemo(() => getPageNumber(currentPages), [currentPages]);

  const onBlurHandler = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      changePageNumber(event.target.value);
      toggleInputMode();
    },
    [changePageNumber, toggleInputMode],
  );

  const onKeyDownHandler = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        changePageNumber((event.target as HTMLInputElement).value);
        toggleInputMode();
      }
    },
    [changePageNumber, toggleInputMode],
  );

  if (isInputMode && !isOverviewOpen) {
    return (
      <Root
        autoFocus
        type="number"
        focused={false}
        variant="outlined"
        inputProps={{
          [ARIA_LABEL]: translations.navigatorInput,
          className: 'page-navigator-input',
          inputMode: 'numeric',
        }}
        data-testid={getTestId(NAVIGATION)}
        onBlur={onBlurHandler}
        onKeyDown={onKeyDownHandler}
      />
    );
  }

  if (totalPageCount < 1) {
    return (
      <Skeleton
        data-testid={getTestId(NAVIGATION_SKELETON)}
        animation="pulse"
        variant="rectangular"
        height={21}
        width={43}
      />
    );
  }

  return (
    <PageCounter
      pageNumber={pageNumber}
      toggleInputMode={toggleInputMode}
      totalPageCount={totalPageCount}
    />
  );
});
