import { Typography } from '@ori-ui/mui';
import useTestId from '@ori/testid-generator';
import type { FC } from 'react';

import { useTranslations } from '../../hooks/useTranslations';
import { LatestCatalogues } from '../LatestCatalogues';
import { OtherCatalogues } from '../OtherCatalogues';
import { CATALOGUES_LANDING_PAGE } from './constants';
import { Root } from './styles';

export const CataloguesLandingPage: FC = () => {
  const { getTestId } = useTestId();
  const { translations } = useTranslations();

  return (
    <Root data-testid={getTestId(CATALOGUES_LANDING_PAGE)}>
      <LatestCatalogues />
      <Typography
        variant="h4"
        component="h4"
      >
        {translations.landingPageOtherCataloguesTitle}
      </Typography>
      <OtherCatalogues />
    </Root>
  );
};
