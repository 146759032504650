import { useToken } from '@ori/auth';
import { useServiceUrls } from '@ori/configuration-manager';
import { useLogger } from '@ori/logger';
import { useHeaders } from '@ori/presentation-http';
import { useCallback } from 'react';

import { getProduct } from '../api/getProduct';
import type { ProductAdded } from '../models';
import { onClickEvent } from '../utils/onClickEvent';
import { sendProductClickAnalytics } from '../utils/sendProductClickAnalytics';

export const useProductClickHandler = () => {
  const localLogger = useLogger('useProductClickHandler');
  const { graphQlNetUrl } = useServiceUrls(['globalApi', 'graphQlNet']);
  const { token } = useToken();
  const { headers } = useHeaders();

  return useCallback(
    async ({ productId, pageIndex }: ProductAdded) => {
      const { product } = await getProduct({
        graphQlUrl: graphQlNetUrl,
        headers,
        productCode: productId,
        token,
      });
      onClickEvent({
        pageIndex,
        product,
      });

      if (product) {
        sendProductClickAnalytics(product, pageIndex);
      } else {
        localLogger.info(`Product with code ${productId} not found.`);
      }
    },
    [graphQlNetUrl, headers, localLogger, token],
  );
};
