import { CATEGORY_BOX_SKELETON, CategoryBox, CategoryBoxSize, CategoryBoxSkeleton } from '@ori/presentation-components';
import useTestId from '@ori/testid-generator';
import times from 'lodash.times';
import type { FC } from 'react';

import { useCataloguesLandingPageContext } from '../../contexts';
import { useTranslations } from '../../hooks/useTranslations';
import { COUNT_OF_CATALOGUE_SKELETONS, OTHER_CATALOGUES, OTHER_CATALOGUES_SKELETON } from './constants';
import { Root } from './styles';

export const OtherCatalogues: FC = () => {
  const { getTestId } = useTestId();
  const { translations } = useTranslations();
  const { data, loading } = useCataloguesLandingPageContext();

  if (loading || !data) {
    return (
      <Root data-testid={getTestId(OTHER_CATALOGUES_SKELETON)}>
        {times(COUNT_OF_CATALOGUE_SKELETONS).map((_, index) => (
          <CategoryBoxSkeleton
            // eslint-disable-next-line react/no-array-index-key -- No other option for passing data to key
            key={`${CATEGORY_BOX_SKELETON}-${index}`}
            showDescription
            animation={loading ? 'pulse' : false}
            size={CategoryBoxSize.MEDIUM}
          />
        ))}
      </Root>
    );
  }

  return (
    <Root data-testid={getTestId(OTHER_CATALOGUES)}>
      {data.otherCatalogues.map((catalogue) => (
        <CategoryBox
          key={catalogue.link}
          buttonText={translations.view}
          description={catalogue.validity}
          imageUrl={catalogue.imageUrl}
          relativeUrl={catalogue.link}
          title={catalogue.title}
          size={CategoryBoxSize.MEDIUM}
        />
      ))}
    </Root>
  );
};
