import { useConfiguration } from '../../hooks';
import { useCatalogueUrlAndId } from '../../hooks/useCatalogueUrlAndId';
import { DOWNLOAD_LINK_PATH_LAST_ITEM } from './constants';

export const useDownloadButtonData = () => {
  const { catalogueUrl } = useCatalogueUrlAndId();
  const { catalogueCode } = useConfiguration();

  return {
    downloadLink: catalogueUrl
      ? new URL(DOWNLOAD_LINK_PATH_LAST_ITEM, catalogueUrl.replace(/\/?$/, '/')).toString()
      : '',
    catalogueId: catalogueCode,
  };
};
