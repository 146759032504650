export const CATALOGUE_DEFAULT_PAGE = 1;
export const CATALOGUE_MAX_PAGE = 32767;
export const CATALOGUE_PROVIDER_TYPE = 'IPaper';
export const DEBOUNCE_DELAY = 250;
export const DEFAULT_MINIBAG_WIDTH = '400px';
export const OVERVIEW_CONTENT_ELEMENT_ID = 'overview-content';
export const OVERVIEW_IMAGE_HEIGHT = 230;
export const PAGE_NUMBER_QUERY_KEY = 'PageNumber';
export const TOOLBAR_HEIGHT = '60px';
export const ARIA_LABEL = 'aria-label';
