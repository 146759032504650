import { useEnvironment } from '@ori/environment';
import { useTranslationsWithNamespaces } from '@ori/i18n';
import { useLogger, type ChildLogger } from '@ori/logger';
import { useGetTenantECatalogueStructure } from '@ori/static-api-client-react';

import { getCorrectCatalogueIdFormat } from '../utils';
import { useCatalogueUrlAndId } from './useCatalogueUrlAndId';

// istanbul ignore next -- No need to test usage of logger.
const onError = (localLogger: ChildLogger) => (error: unknown) => {
  localLogger.error(new Error('Failed fetch of catalogue structure', { cause: error }));
};

export const useGetCatalogueStructure = () => {
  const { language } = useTranslationsWithNamespaces();
  const { tenant } = useEnvironment();
  const localLogger = useLogger('useGetCatalogueStructure');
  const { catalogueId } = useCatalogueUrlAndId();
  const eCatalogueId = getCorrectCatalogueIdFormat(catalogueId);
  const { data, isValidating } = useGetTenantECatalogueStructure(
    tenant,
    eCatalogueId,
    { lang: language },
    {
      swr: {
        enabled: Boolean(eCatalogueId),
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        onError: onError(localLogger),
      },
    },
  );
  const totalPageCount = data?.pages.length ?? 0;

  return {
    totalPageCount,
    isLoadingTotalPageCount: isValidating,
  };
};
