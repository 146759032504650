import { getClientQueryParam, getServerQueryParam } from '@ori/presentation-utils';
import { isNonNullable } from '@ori/ts-utils';
import type { IncomingMessage } from 'node:http';

export const PAGE_NUMBER_PARAM_NAME = 'PageNumber';
export const getPageNumber = (request?: IncomingMessage) => {
  const pageNumber = Number(
    isNonNullable(request)
      ? getServerQueryParam(request, PAGE_NUMBER_PARAM_NAME)
      : getClientQueryParam(PAGE_NUMBER_PARAM_NAME),
  );

  return Number.isInteger(pageNumber) ? pageNumber : null;
};
