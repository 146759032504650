import * as ProductClickEvent from '@ori-events/product-click';
import { Placement } from '@ori/analytics-data';

import type { GetProductQuery } from '../models';

export const sendProductClickAnalytics = (product: NonNullable<GetProductQuery['product']>, pageIndex: number) => {
  const event = new CustomEvent<ProductClickEvent.Data>(ProductClickEvent.Name, {
    detail: {
      placement: Placement.DigitalCatalogueECatalogue,
      position: pageIndex + 1,
      products: [
        {
          formattedPrice: product.formattedPrice?.price?.currentPrice ?? '',
          globalBrandName: '',
          globalCategoryName: '',
          globalName: '',
          itemCode: product.productCode ?? '',
          localBrandName: product.concept?.brand?.name ?? '',
          localCategoryName: '',
          localName: product.concept?.name ?? '',
          media: product.concept?.imageUrl ? [product.concept.imageUrl] : [],
        },
      ],
    },
  });

  window.dispatchEvent(event);
};
