import type { CatalogueUrlParams } from '../models';
import { getCatalogueMetadata } from './getCatalogueMetadata';
import { getCatalogueMetadataForProviderType } from './getCatalogueMetadataForProviderType';

export const getCatalogueUrlAndId = ({ catalogueCode, data, isFlyer }: CatalogueUrlParams) => {
  const catalogueMetadata = getCatalogueMetadata({ catalogueCode, isFlyer, data });
  const catalogueMetadataForProviderType = getCatalogueMetadataForProviderType(catalogueMetadata);
  const catalogueUrl = catalogueMetadataForProviderType?.url?.replace(/\/?$/, '');
  const catalogueId = catalogueMetadataForProviderType?.id;

  return {
    catalogueUrl,
    catalogueId,
  };
};
