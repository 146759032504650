import { styled } from '@ori-ui/mui';
import { CATEGORY_BOX_DESKTOP_MEDIUM_WIDTH, CATEGORY_BOX_MOBILE_MEDIUM_WIDTH } from '@ori/presentation-components';

export const Root = styled('div')(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(4, 2),
  gridTemplateColumns: `repeat(2, minmax(0, ${CATEGORY_BOX_MOBILE_MEDIUM_WIDTH}px))`,
  [theme.breakpoints.up('sm')]: {
    gap: theme.spacing(6),
    gridTemplateColumns: `repeat(3, minmax(0, ${CATEGORY_BOX_DESKTOP_MEDIUM_WIDTH}px))`,
  },
  [theme.breakpoints.up(750)]: {
    gridTemplateColumns: `repeat(4, minmax(0, ${CATEGORY_BOX_DESKTOP_MEDIUM_WIDTH}px))`,
  },
  [theme.breakpoints.up('md')]: {
    gap: theme.spacing(8),
    gridTemplateColumns: `repeat(5, minmax(0, ${CATEGORY_BOX_DESKTOP_MEDIUM_WIDTH}px))`,
  },
}));
