import { styled } from '@ori-ui/mui';

import { TOOLBAR_HEIGHT } from '../../constants';

export const Root = styled('div')(({ theme }) => ({
  alignItems: 'center',
  background: theme.palette.common.white,
  boxSizing: 'border-box',
  color: theme.palette.grey[900],
  display: 'flex',
  fontSize: '2.6rem',
  height: TOOLBAR_HEIGHT,
  justifyContent: 'space-between',
  maxWidth: '100%',
  width: '100%',
  padding: theme.spacing(0, 2),

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(0, 5),
  },
}));

export const ActionsContainer = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  gap: theme.spacing(3),
  justifyContent: 'center',

  '&:first-of-type': {
    justifyContent: 'flex-start',
  },

  '&:last-child': {
    justifyContent: 'flex-end',
  },

  '&:only-child': {
    justifyContent: 'space-between',
  },
}));
