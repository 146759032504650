import { Toggle } from '@ori-ui/components';
import { Dashboard, Editorial } from '@ori-ui/icons';
import { alpha, Fade, styled } from '@ori-ui/mui';
import useTestId from '@ori/testid-generator';
import type { FC } from 'react';
import React, { memo } from 'react';

import { TOOLBAR_HEIGHT } from '../../constants/variables';
import { useOverviewContext } from '../../hooks/useOverviewContext';
import { useTranslations } from '../../hooks/useTranslations';
import { OverviewContent } from '../OverviewContent';
import { OVERVIEW_SWITCH } from './constants';

const Overlay = styled('div')(({ theme }) => ({
  background: alpha(theme.palette.common.black, 0.3),
  bottom: 0,
  boxSizing: 'border-box',
  left: 0,
  position: 'absolute',
  top: TOOLBAR_HEIGHT,
  width: '100%',
}));

export const Overview: FC = memo(() => {
  const { isOverviewOpen, toggleOverviewOpen } = useOverviewContext();
  const { getTestId } = useTestId();
  const { translations } = useTranslations();

  return (
    <>
      <Toggle
        aria-label={`${translations.singlePage} / ${translations.overview}`}
        checked={isOverviewOpen}
        data-testid={getTestId(OVERVIEW_SWITCH)}
        firstIcon={<Editorial />}
        secondIcon={<Dashboard />}
        title={`${translations.singlePage} / ${translations.overview}`}
        onChange={toggleOverviewOpen}
      />
      <Fade
        mountOnEnter
        in={isOverviewOpen}
        timeout={300}
      >
        <Overlay>
          <OverviewContent />
        </Overlay>
      </Fade>
    </>
  );
});
