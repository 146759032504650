import { PAGE_NUMBER_QUERY_KEY } from '../../constants';

export const updatePageNumberUrlParam = (pageNumber: number) => {
  const urlParams = new URLSearchParams(window.location.search);
  const pageNumberValue = pageNumber.toString();

  if (urlParams.get(PAGE_NUMBER_QUERY_KEY) === pageNumberValue) {
    // We will proceed with updating the history only in case we are moving
    // to the different page than it is already present in current history state
    return;
  }

  urlParams.set(PAGE_NUMBER_QUERY_KEY, pageNumberValue);

  const newRelativePathQuery = `${window.location.pathname}?${urlParams.toString()}`;

  window.history.pushState(null, document.title, newRelativePathQuery);
};
