import * as DownloadFile from '@ori-events/download-file';
import { IconButton } from '@ori-ui/components';
import { Download } from '@ori-ui/icons';
import { Placement } from '@ori/analytics-data';
import useTestId from '@ori/testid-generator';
import type { FC } from 'react';
import { memo, useCallback } from 'react';

import { useTranslations } from '../../hooks/useTranslations';
import { DOWNLOAD_BUTTON } from './constants';
import { useDownloadButtonData } from './useDownloadButtonData';

export const DownloadButton: FC = memo(() => {
  const { translations } = useTranslations();
  const { getTestId } = useTestId();
  const { catalogueId, downloadLink } = useDownloadButtonData();
  const dispatchDownloadFileEvent = useCallback(() => {
    window.dispatchEvent(
      new CustomEvent<DownloadFile.Data>(DownloadFile.Name, {
        detail: {
          id: catalogueId,
          name: `${catalogueId}.pdf`,
          pageUrl: window.location.href,
          placement: Placement.DigitalCatalogueECatalogue,
          target: downloadLink,
        },
      }),
    );
  }, [catalogueId, downloadLink]);

  if (!downloadLink) {
    return null;
  }

  return (
    <IconButton
      aria-label={translations.download}
      data-testid={getTestId(DOWNLOAD_BUTTON)}
      href={downloadLink}
      title={translations.download}
      variant="contained"
      color="default"
      size="large"
      onClick={dispatchDownloadFileEvent}
    >
      <Download fontSize="inherit" />
    </IconButton>
  );
});
