import { useToken } from '@ori/auth';
import { useServiceUrls } from '@ori/configuration-manager';
import { useEnvironment } from '@ori/environment';
import { SWRConfig } from '@ori/fetching';
import { ApiClientProvider as GlobalApiClientProvider } from '@ori/global-api-client-react';
import { useTranslationsWithNamespaces } from '@ori/i18n';
import { ApiClientProvider as StaticApiClientProvider } from '@ori/static-api-client-react';
import type { FC, PropsWithChildren } from 'react';
import React from 'react';

import { swrConfig } from '../../constants';

export const ApiProvider: FC<PropsWithChildren> = ({ children }) => {
  const { language } = useTranslationsWithNamespaces();
  const { tenant } = useEnvironment();
  const { globalApiUrl, staticApiUrl } = useServiceUrls(['globalApi', 'staticApi']);
  const { token } = useToken();

  return (
    <SWRConfig value={swrConfig}>
      <GlobalApiClientProvider
        baseUrl={globalApiUrl}
        language={language}
        tenant={tenant}
        token={token}
      >
        <StaticApiClientProvider
          baseUrl={staticApiUrl}
          language={language}
          tenant={tenant}
        >
          {children}
        </StaticApiClientProvider>
      </GlobalApiClientProvider>
    </SWRConfig>
  );
};
