import { styled } from '@ori-ui/mui';

export const NativeShare = styled('a')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  fontSize: '2.6rem',
  height: '40px',
  position: 'relative',
  zIndex: 199,
}));
