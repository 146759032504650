import { useEnvironment } from '@ori/environment';
import { useTranslationsWithNamespaces } from '@ori/i18n';
import type { ChildLogger } from '@ori/logger';
import { useLogger } from '@ori/logger';
import { useUserSegmentationData } from '@ori/presentation-http';
import { isSSR } from '@ori/presentation-utils';
import { useGetTenantApplicationsCatalogueOverview } from '@ori/static-api-client-react';
import { useEffect, useMemo, useState } from 'react';

import { getCatalogueUrlAndId } from '../utils';
import { useConfiguration } from './useConfiguration';

// istanbul ignore next -- No need to test usage of logger.
const onError = (localLogger: ChildLogger) => (error: unknown) => {
  localLogger.error(new Error('Failed fetch of catalogue overview', { cause: error }));
};

export const useCatalogueUrlAndId = () => {
  const { language } = useTranslationsWithNamespaces();
  const { tenant } = useEnvironment();
  const localLogger = useLogger('useCatalogueUrlAndId');
  const { catalogueCode, isFlyer } = useConfiguration();
  const [catalogueUrl, setCatalogueUrl] = useState<string | null>(null);
  const [catalogueId, setCatalogueId] = useState<string | null>(null);
  const { customerTypeId, loading, visitorSegment } = useUserSegmentationData();
  const { data, isValidating } = useGetTenantApplicationsCatalogueOverview(
    tenant,
    {
      customerTypeId: customerTypeId ?? undefined,
      lang: language,
      visitorSegment: visitorSegment ?? undefined,
    },
    {
      swr: {
        enabled: !loading,
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        onError: onError(localLogger),
      },
    },
  );
  useEffect(() => {
    const currentCatalogUrlAndId = getCatalogueUrlAndId({
      catalogueCode,
      data,
      isFlyer,
    });
    setCatalogueUrl(currentCatalogUrlAndId.catalogueUrl ?? null);
    setCatalogueId(currentCatalogUrlAndId.catalogueId ?? null);
  }, [catalogueCode, isFlyer, data]);

  return useMemo(
    () => ({
      catalogueId,
      catalogueUrl,
      isLoading: isValidating || isSSR(),
    }),
    [catalogueId, catalogueUrl, isValidating],
  );
};
