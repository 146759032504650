import type { FC } from 'react';
import React, { memo } from 'react';

import { useConfiguration } from '../../hooks';
import { DownloadButton } from '../DownloadButton';
import { Overview } from '../Overview';
import { PageNavigator } from '../PageNavigator';
import { ShareButton } from '../Share';
import { ActionsContainer, Root } from './styles';

/**
 *  Top section of the catalogue.
 *  It contains Share, Download, PageNavigator and Overview components.
 */
export const Toolbar: FC = memo(() => {
  const {
    config: { downloadEnabled, sharingPageEnabled },
  } = useConfiguration();

  return (
    <Root role="toolbar">
      <ActionsContainer>
        <Overview />
      </ActionsContainer>
      <ActionsContainer>
        <PageNavigator />
      </ActionsContainer>
      <ActionsContainer>
        {downloadEnabled ? <DownloadButton /> : null}
        {sharingPageEnabled ? <ShareButton /> : null}
      </ActionsContainer>
    </Root>
  );
});
