import { DEFAULT_MINIBAG_WIDTH } from '../constants/variables';

export const setCssVariables = () => {
  const bodyElement = document.body;
  const newTopNavHeight = getComputedStyle(bodyElement).getPropertyValue('--top-nav-height');
  const oldTopNav = document.querySelector<HTMLDivElement>('header.n-header');

  bodyElement.style.setProperty('--ecatalogue-toolbar-position-animation', 'height 0.3s ease-in-out');

  bodyElement.style.setProperty('--minibag-desktop-width', DEFAULT_MINIBAG_WIDTH);

  if (newTopNavHeight) {
    bodyElement.style.setProperty('--ecatalogue-toolbar-position', newTopNavHeight);
  } else if (oldTopNav) {
    bodyElement.style.setProperty('--ecatalogue-toolbar-position', `${String(oldTopNav.offsetHeight)}px`);
  }
};
