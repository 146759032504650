import { DefaultLayoutFeature } from '@ori-appshell/layouts';

export const PageFeatures = {
  ...DefaultLayoutFeature,
  /** It enable/disable sending search events to analytics. */
  SendSearchEvents: '3767D0FE-0404-4FC8-A552-4B3F2A4036C7',
} as const;

// TODO -- Remove this when whole monorepo is migrated to GraphQLNET
export const PageFeaturesWithUseGraphQLNET = {
  ...PageFeatures,
  UseGraphQLNET: 'A46B2F16-A240-4B51-B532-6BAAB94115A7',
} as const;

export const ProductDetailPageFeatures = {
  ...PageFeatures,
  ShowOffersOnPdp: '5F6D2451-BACD-445F-A5F2-41A981CA684C',
  ShowOffersTypeBuyOnPdp: 'C9CE100E-F6D3-4217-ABE8-0964B8538E84',
  ShowOffersTypeGetOnPdp: 'B974F70F-6E13-4E16-8A7B-9A0C0489AAE1',
  ShowOffersSetsOnPdp: 'A974F70F-6E13-4E16-8A7B-9A0C0489BCB2',
  EnableMedalliaQuizOnPDP: 'F00EC4CD-D661-46B3-8D68-40506978DE31',
  Olapic2: 'FE17E0E8-3943-44D9-953C-A9D9228218F9',
  // Show recommended products on PDP instead of related products (ASMS PDP only)
  EnableRecomendedProductsOnPdp: '6949B120-3A39-467A-BCDD-EFA30488B65F',
  UseGraphQLNET: 'A46B2F16-A240-4B51-B532-6BAAB94115A7',
} as const;
