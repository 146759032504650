/* eslint-disable @typescript-eslint/naming-convention -- iPaper is the name of the flipbook */
import { styled } from '@ori-ui/mui';
import { useWindowSize } from '@ori/presentation-hooks';
import type { FC, ReactNode } from 'react';
import { memo, useEffect } from 'react';

import { Toolbar } from '../../components/Toolbar';
import { DEFAULT_MINIBAG_WIDTH, TOOLBAR_HEIGHT } from '../../constants/variables';
import { IPAPER_ROOT_ID, setCssVariables } from '../../utils';

// TODO -- Replace with sm when Checkout adjust the mobile breakpoint on MiniShoppingBag
const CHECKOUT_MOBILE_BREAKPOINT = 1024;

interface IPaperWrapperProps {
  children?: ReactNode;
}

const Root = styled('div')(({ theme }) => ({
  background: theme.palette.common.white,
  display: 'grid',
  gridTemplateRows: `${TOOLBAR_HEIGHT} 1fr`,
  height: 'calc(100dvh - var(--top-nav-height, 94px))',
  maxWidth: '100%',
  position: 'fixed',
  top: 'var(--top-nav-height, 94px)',
  width: '100vw',
  zIndex: 1,
  [theme.breakpoints.up(CHECKOUT_MOBILE_BREAKPOINT)]: {
    height: 'calc(100dvh - var(--top-nav-height, 98px))',
    top: 'var(--top-nav-height, 98px)',
    width: `calc(100vw - var(--minibag-desktop-width, ${DEFAULT_MINIBAG_WIDTH}))`,
  },
}));

export const IPaperWrapper: FC<IPaperWrapperProps> = memo(({ children }) => {
  const { width } = useWindowSize({ delay: 500 });

  useEffect(() => {
    setCssVariables();
  }, [children, width]);

  return (
    <Root id={IPAPER_ROOT_ID}>
      <Toolbar />
      {children}
    </Root>
  );
});
