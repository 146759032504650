import { LazyImage } from '@ori-ui/components';
import type { SxProps } from '@ori-ui/mui';
import { Skeleton } from '@ori-ui/mui';
import useTestId from '@ori/testid-generator';
import type { FC } from 'react';
import React from 'react';

import { OVERVIEW_IMAGE_HEIGHT } from '../../constants/variables';
import { IMAGE_WITH_SKELETON } from './constants';

interface ImageWithSkeletonProps {
  src: string;
  alt?: string;
}

const style: SxProps = {
  height: OVERVIEW_IMAGE_HEIGHT,
  maxWidth: '165px',
  objectFit: 'cover',
  padding: 0,
};

export const ImageWithSkeleton: FC<ImageWithSkeletonProps> = ({ src, alt }) => {
  const { getTestId } = useTestId();

  return (
    <LazyImage
      alt={alt}
      src={src}
      sx={style}
      fallback={
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={160}
          sx={style}
        />
      }
      data-testid={getTestId(IMAGE_WITH_SKELETON)}
    />
  );
};
