import { styled } from '@ori-ui/mui';

export const Root = styled('ul')(({ theme }) => ({
  background: theme.palette.common.white,
  boxSizing: 'border-box',
  display: 'grid',
  gridAutoRows: 'min-content',
  gridTemplateColumns: 'repeat(auto-fill, minmax(175px, 1fr))',
  justifyContent: 'center',
  listStyle: 'none',
  maxHeight: '100%',
  overflow: 'auto',
  gap: theme.spacing(6, 0),
  padding: theme.spacing(2, 1),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4),
    gap: theme.spacing(10, 10),
  },
}));
