import { ButtonBase, styled } from '@ori-ui/mui';

import { OVERVIEW_IMAGE_HEIGHT } from '../../constants';

export const Item = styled('li')(({ theme }) => ({
  '&:first-of-type': {
    gridColumn: '1 / -1',
    [theme.breakpoints.up('xs')]: {
      gridColumn: 'auto',
    },
  },
}));

export const Button = styled(ButtonBase)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  margin: 'auto',
  overflow: 'hidden',
  transition: 'filter 0.2s',

  '&:hover, &:focus': {
    filter: 'brightness(0.8)',
  },
}));

export const PageNumber = styled('div')(({ theme }) => ({
  display: 'block',
  width: '38px',
  height: '23px',
  background: theme.palette.grey[100],
  borderRadius: '0 0 5px 5px',
}));

export const ImageWrapper = styled('div')(() => ({
  borderRadius: '5px',
  height: OVERVIEW_IMAGE_HEIGHT,
  overflow: 'hidden',
}));
