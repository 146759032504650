// istanbul ignore file -- No need to test passing of data to context.
import { useToken } from '@ori/auth';
import { useEnvironment } from '@ori/environment';
import { TranslationsNamespaceProvider, useLanguage } from '@ori/i18n';
import { ErrorBoundary, LoggerProvider } from '@ori/logger';
import { HeadersProvider, UserSegmentationDataProvider } from '@ori/presentation-http';
import { Team, TestIdProvider } from '@ori/testid-generator';
import type { FC } from 'react';

import { CataloguesLandingPage } from '../components';
import { translationsNamespace } from '../constants';
import { APP_NAME, PACKAGE_NAME } from '../constants-generated';
import { CataloguesLandingPageProvider } from '../contexts';
import { mainLogger } from '../logger';
import { ApiProvider } from './ApiProvider';

export const CataloguesLandingPageContainer: FC = () => {
  const { tenant } = useEnvironment();
  const { language } = useLanguage();
  const { customerId, token } = useToken();

  return (
    <TestIdProvider
      team={Team.Presentation}
      project={APP_NAME}
    >
      <LoggerProvider logger={mainLogger}>
        <ErrorBoundary areaName="CataloguesLandingPageContainer">
          <ApiProvider>
            <TranslationsNamespaceProvider namespace={translationsNamespace}>
              <UserSegmentationDataProvider>
                <HeadersProvider
                  customerId={customerId}
                  language={language}
                  sourceAppName={PACKAGE_NAME}
                  tenant={tenant}
                  token={token}
                >
                  <CataloguesLandingPageProvider>
                    <CataloguesLandingPage />
                  </CataloguesLandingPageProvider>
                </HeadersProvider>
              </UserSegmentationDataProvider>
            </TranslationsNamespaceProvider>
          </ApiProvider>
        </ErrorBoundary>
      </LoggerProvider>
    </TestIdProvider>
  );
};
