import { styled } from '@ori-ui/mui';
import { CATEGORY_BOX_DESKTOP_LARGE_WIDTH, CATEGORY_BOX_MOBILE_LARGE_WIDTH } from '@ori/presentation-components';
import { shouldForwardProp } from '@ori/presentation-utils';

import { LatestCataloguesDescription } from '../LatestCataloguesDescription';

export const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
}));

interface InnerProps {
  showNextCatalogue: boolean;
}

export const Inner = styled('div', { shouldForwardProp: shouldForwardProp() })<InnerProps>(
  ({ showNextCatalogue, theme }) => ({
    display: 'grid',
    gridTemplateColumns: `minmax(0, ${CATEGORY_BOX_MOBILE_LARGE_WIDTH}px) minmax(0, ${CATEGORY_BOX_MOBILE_LARGE_WIDTH}px)`,
    gap: theme.spacing(2),
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: `${showNextCatalogue ? `minmax(0, ${CATEGORY_BOX_DESKTOP_LARGE_WIDTH}px)` : ''} minmax(0, ${CATEGORY_BOX_DESKTOP_LARGE_WIDTH}px) minmax(0, 400px)`,
      gap: theme.spacing(8),
    },
  }),
);

export const MobileLatestCataloguesDescription = styled(LatestCataloguesDescription)(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

export const DesktopLatestCataloguesDescription = styled(LatestCataloguesDescription)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
  },
}));
