import { CATALOGUE_DEFAULT_PAGE, PAGE_NUMBER_QUERY_KEY } from '../../constants/variables';
import { transformPageInput } from './transformPageInput';

export const getPageNumberParamFromUrl = () => {
  const pageNumberParam = new URLSearchParams(window.location.search).get(PAGE_NUMBER_QUERY_KEY);

  if (!pageNumberParam) {
    return CATALOGUE_DEFAULT_PAGE;
  }

  return transformPageInput(pageNumberParam);
};
