import { useContext } from 'react';

import { OverviewContext } from '../contexts';
import type { OverviewContextValues } from '../models';

export const useOverviewContext = (): OverviewContextValues => {
  const context = useContext(OverviewContext);

  if (context) {
    return context;
  }

  throw new Error('OverviewContext value must be set.');
};
