import { gql } from '@ori/fetching';

export const productQuery = gql`
  query GetProduct($productCode: ID!) {
    product(productCode: $productCode) {
      concept {
        brand {
          name
        }
        imageUrl
        name
        products {
          productCode
        }
      }
      formattedPrice {
        price {
          currentPrice
        }
      }
      labels {
        labelKey
        productLabelDomain
        title
      }
      productCode
      formattedPrice {
        price {
          currentPrice
        }
      }
    }
  }
`;
