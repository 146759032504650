import type { StaticApplicationsCatalogueIPaperConfigurationCatalogueIPaperConfigurationDto } from '@ori/static-api-client-react';
import type { FC, PropsWithChildren } from 'react';
import { createContext, useMemo } from 'react';

export interface ConfigurationContextValues {
  /** Code of displayed catalogue. */
  catalogueCode: string;
  /** Configuration for Catalogue IPaper. */
  config: Omit<
    StaticApplicationsCatalogueIPaperConfigurationCatalogueIPaperConfigurationDto,
    'productInfoDialogEnabled'
  >;
  /** Truthy if catalogue is flyer (and is not current catalogue). */
  isFlyer: boolean;
}

export const configurationContextDefaultValues: ConfigurationContextValues = {
  catalogueCode: '',
  config: {
    downloadEnabled: false,
    fullScreenEnabled: false,
    sharingEnabled: false,
    quickBuyEnabled: false,
    sharingCatalogueEnabled: false,
    sharingPageEnabled: false,
    sharingOfflineCatalogueEnabled: false,
  },
  isFlyer: false,
};

export const ConfigurationContext = createContext<ConfigurationContextValues>(configurationContextDefaultValues);

export const ConfigurationProvider: FC<PropsWithChildren<ConfigurationContextValues>> = ({
  catalogueCode,
  config,
  isFlyer,
  children,
}) => {
  const value = useMemo(() => ({ catalogueCode, config, isFlyer }), [catalogueCode, config, isFlyer]);
  if (!catalogueCode) {
    return null;
  }

  return <ConfigurationContext.Provider value={value}>{children}</ConfigurationContext.Provider>;
};
