import useTestId from '@ori/testid-generator';
import type { FC } from 'react';

import { useCataloguesLandingPageContext } from '../../contexts';
import { Catalogues } from './Catalogues';
import { LATEST_CATALOGUES } from './constants';
import { DesktopLatestCataloguesDescription, Inner, MobileLatestCataloguesDescription, Root } from './styles';

export const LatestCatalogues: FC = () => {
  const { getTestId } = useTestId();
  const { data, loading } = useCataloguesLandingPageContext();
  const showNextCatalogue = loading || !data || !!data.nextCatalogue;

  return (
    <Root data-testid={getTestId(LATEST_CATALOGUES)}>
      <MobileLatestCataloguesDescription />
      <Inner showNextCatalogue={showNextCatalogue}>
        <Catalogues />
        <DesktopLatestCataloguesDescription />
      </Inner>
    </Root>
  );
};
