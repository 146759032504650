import { CategoryBox, CategoryBoxSize, CategoryBoxSkeleton } from '@ori/presentation-components';
import type { FC } from 'react';

import { useCataloguesLandingPageContext } from '../../contexts';
import { useTranslations } from '../../hooks/useTranslations';

export const Catalogues: FC = () => {
  const { translations } = useTranslations();
  const { data, loading } = useCataloguesLandingPageContext();

  if (loading || !data) {
    return (
      <>
        <CategoryBoxSkeleton
          showDescription
          animation={loading ? 'pulse' : false}
          size={CategoryBoxSize.LARGE}
        />
        <CategoryBoxSkeleton
          showDescription
          animation={loading ? 'pulse' : false}
          size={CategoryBoxSize.LARGE}
        />
      </>
    );
  }

  const { currentCatalogue, nextCatalogue } = data;

  return (
    <>
      {currentCatalogue ? (
        <CategoryBox
          buttonText={translations.view}
          description={currentCatalogue.validity}
          imageUrl={currentCatalogue.imageUrl}
          relativeUrl={currentCatalogue.link}
          title={currentCatalogue.title}
          size={CategoryBoxSize.LARGE}
        />
      ) : (
        <CategoryBoxSkeleton
          showDescription
          animation={false}
          size={CategoryBoxSize.LARGE}
        />
      )}
      {nextCatalogue ? (
        <CategoryBox
          buttonText={translations.view}
          description={nextCatalogue.validity}
          imageUrl={nextCatalogue.imageUrl}
          relativeUrl={nextCatalogue.link}
          title={nextCatalogue.title}
          size={CategoryBoxSize.LARGE}
        />
      ) : null}
    </>
  );
};
