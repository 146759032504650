import * as AddToBasketEvent from '@ori-events/add-to-basket';
import { Method } from '@ori-events/add-to-basket';
import * as OpenProductInfoDialog from '@ori-events/open-product-info-dialog';
import * as OpenShadePicker from '@ori-events/open-shade-picker';
import type { AddToBasketAnalyticsData } from '@ori/analytics-data';
import { Placement } from '@ori/analytics-data';
import { mapHasAutoSubscription } from '@ori/product-box';

import type { GetProductQuery } from '../models';

interface OnClickEventParams {
  pageIndex: number;
  product: GetProductQuery['product'];
}
export const onClickEvent = ({ pageIndex, product }: OnClickEventParams) => {
  if (product?.productCode == null) {
    return;
  }

  const hasAutoSubscription = mapHasAutoSubscription(product.labels);
  const isMultiShade = product.concept?.products && product.concept.products.length > 1;

  if (hasAutoSubscription) {
    window.dispatchEvent(
      new CustomEvent<OpenProductInfoDialog.Data>(OpenProductInfoDialog.Name, {
        detail: {
          mode: OpenProductInfoDialog.DialogMode.StandardBuy,
          placement: Placement.DigitalCatalogueECatalogue,
          productCodes: [product.productCode],
        },
      }),
    );
  } else if (isMultiShade) {
    window.dispatchEvent(
      new CustomEvent<OpenShadePicker.Data>(OpenShadePicker.Name, {
        detail: {
          placement: Placement.DigitalCatalogueECatalogue,
          productCode: product.productCode,
        },
      }),
    );
  } else {
    window.dispatchEvent(
      new CustomEvent<AddToBasketAnalyticsData & AddToBasketEvent.Data>(AddToBasketEvent.Name, {
        detail: {
          formattedPrice: product.formattedPrice?.price?.currentPrice ?? '',
          itemCode: product.productCode,
          localName: product.concept?.name ?? '',
          method: Method.BUY_FOR_MONEY,
          placement: Placement.DigitalCatalogueECatalogue,
          position: pageIndex + 1,
          quantity: 1,
        },
      }),
    );
  }
};
