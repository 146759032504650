import * as AddToBasketEvent from '@ori-events/add-to-basket';
import type { AddToBasketAnalyticsData } from '@ori/analytics-data';
import { Placement } from '@ori/analytics-data';

import type { ProductAdded } from '../models';

export type AddToBasketData = AddToBasketAnalyticsData & AddToBasketEvent.Data;

export const addToBasket = ({ pageIndex, productId, title, quantity }: ProductAdded) => {
  const event = new CustomEvent<AddToBasketData>(AddToBasketEvent.Name, {
    detail: {
      globalName: '',
      itemCode: Number.parseInt(productId, 10).toString(),
      localName: title,
      placement: Placement.DigitalCatalogueECatalogue,
      position: pageIndex + 1,
      quantity,
    },
  });
  window.dispatchEvent(event);
};
