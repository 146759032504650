import { Typography } from '@ori-ui/mui';
import useTestId from '@ori/testid-generator';
import type { FC } from 'react';

import { useOverviewContext } from '../../hooks/useOverviewContext';
import { useTranslations } from '../../hooks/useTranslations';
import { COUNTER } from './constants';

export interface PageCounterProps {
  pageNumber?: number | string;
  totalPageCount?: number | string;
  toggleInputMode: () => void;
}

export const PageCounter: FC<PageCounterProps> = ({ pageNumber, totalPageCount, toggleInputMode }) => {
  const { getTestId } = useTestId();
  const { translations } = useTranslations();
  const { isOverviewOpen } = useOverviewContext();

  return (
    <Typography
      data-testid={getTestId(COUNTER)}
      fontWeight="bold"
      onClick={toggleInputMode}
    >
      {isOverviewOpen ? `${translations.allPages} (${totalPageCount})` : `${pageNumber} / ${totalPageCount}`}
    </Typography>
  );
};
