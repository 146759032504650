import { useToken } from '@ori/auth';
import { useEnvironment } from '@ori/environment';
import type { TranslationsContext } from '@ori/i18n';
import { TranslationsNamespaceProvider } from '@ori/i18n';
import { ErrorBoundary, LoggerProvider } from '@ori/logger';
import { HeadersProvider, UserSegmentationDataProvider } from '@ori/presentation-http';
import { Team, TestIdProvider } from '@ori/testid-generator';
import { memo, type FC } from 'react';

import { IPaper } from '../components';
import { translationsNamespace } from '../constants';
import { APP_NAME, PACKAGE_NAME } from '../constants-generated';
import type { ConfigurationContextValues } from '../contexts';
import { ConfigurationProvider, OverviewProvider, PageNumberProvider } from '../contexts';
import { mainLogger } from '../logger';
import { getPageNumber } from '../utils';
import { ApiProvider } from './ApiProvider';

export type CatalogueIPaperContainerProps = ConfigurationContextValues &
  Required<Pick<TranslationsContext, 'language'>>;

export const CatalogueIPaperContainer: FC<CatalogueIPaperContainerProps> = memo(
  ({ catalogueCode, config, isFlyer, language }) => {
    const initialPageNumber = getPageNumber();
    const { tenant } = useEnvironment();
    const { customerId, token } = useToken();

    return (
      <TestIdProvider
        team={Team.Presentation}
        project={APP_NAME}
      >
        <LoggerProvider logger={mainLogger}>
          <ErrorBoundary areaName="CatalogueIPaperContainer">
            <ApiProvider>
              <TranslationsNamespaceProvider namespace={translationsNamespace}>
                <UserSegmentationDataProvider>
                  <HeadersProvider
                    customerId={customerId}
                    language={language}
                    sourceAppName={PACKAGE_NAME}
                    tenant={tenant}
                    token={token}
                  >
                    <ConfigurationProvider
                      catalogueCode={catalogueCode}
                      config={config}
                      isFlyer={isFlyer}
                    >
                      <PageNumberProvider initialPageNumber={initialPageNumber}>
                        <OverviewProvider>
                          <IPaper />
                        </OverviewProvider>
                      </PageNumberProvider>
                    </ConfigurationProvider>
                  </HeadersProvider>
                </UserSegmentationDataProvider>
              </TranslationsNamespaceProvider>
            </ApiProvider>
          </ErrorBoundary>
        </LoggerProvider>
      </TestIdProvider>
    );
  },
);
