import type { Catalogue, CatalogueFragment, CataloguesLandingPage, CataloguesLandingPageQuery } from '../models';
import { CatalogueType } from '../models/graphql-types/net';

type FormatValidityDate = (date: Date) => string;

interface ParseValidityDateParams extends Partial<Pick<CatalogueFragment, 'validFrom' | 'validTo'>> {
  formatValidityDate: FormatValidityDate;
}

export const parseValidityDate = ({
  validFrom,
  validTo,
  formatValidityDate,
}: ParseValidityDateParams): string | null => {
  if (validFrom === undefined || validTo === undefined || new Date(validTo).getFullYear() > 3000) {
    return null;
  }

  return `${formatValidityDate(new Date(validFrom))} - ${formatValidityDate(new Date(validTo))}`;
};

export const parseCatalogue =
  (formatValidityDate: FormatValidityDate) =>
  (catalogue: CatalogueFragment | null | undefined): Catalogue | null => {
    const { validFrom, validTo, imageUrl, link, title } = catalogue ?? {};
    const catalogueLink = link?.relativeUrl;
    if (!catalogueLink) {
      return null;
    }

    return {
      imageUrl: imageUrl ?? null,
      link: catalogueLink,
      title: title ?? null,
      validity: parseValidityDate({ validFrom, validTo, formatValidityDate }),
    };
  };

interface GetDigitalCatalogueParams {
  catalogueType: CatalogueType;
  data: CataloguesLandingPageQuery;
  formatValidityDate: FormatValidityDate;
}

export const getDigitalCatalogue = ({
  catalogueType,
  data,
  formatValidityDate,
}: GetDigitalCatalogueParams): Catalogue | null => {
  const currentCatalogue = data.application?.catalogueLandingPage?.digitalCatalogues?.find(
    (catalogue) => catalogue?.catalogueType === catalogueType,
  );

  return parseCatalogue(formatValidityDate)(currentCatalogue);
};

export const parseCataloguesLandingPageData = (
  data: CataloguesLandingPageQuery,
  formatValidityDate: FormatValidityDate,
): CataloguesLandingPage => ({
  currentCatalogue: getDigitalCatalogue({ catalogueType: CatalogueType.DigitalCurrent, data, formatValidityDate }),
  nextCatalogue: getDigitalCatalogue({ catalogueType: CatalogueType.DigitalNext, data, formatValidityDate }),
  otherCatalogues: (data.application?.catalogueLandingPage?.otherCatalogues ?? [])
    .map(parseCatalogue(formatValidityDate))
    .filter((catalogue) => catalogue !== null),
});
