/* istanbul ignore file -- No need to test constant */
import type { SWRConfiguration } from '@ori/fetching';

export const swrConfig: SWRConfiguration = {
  errorRetryCount: 3,
  errorRetryInterval: 10000,
  focusThrottleInterval: 300000,
  shouldRetryOnError: true,
  suspense: false,
};
