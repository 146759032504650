/* istanbul ignore file */
import { useLogger } from '@ori/logger';
import { useCallback, useEffect, useState } from 'react';

import type { OnPageChange } from '../models';
import { loadIPaperAPIScript } from '../utils';
import { getApiInstance } from '../utils/getApiInstance';
import { getPageNumberParamFromUrl } from '../utils/pageCounter';
import { useCatalogueUrlAndId } from './useCatalogueUrlAndId';
import { usePageNumberContext } from './usePageNumberContext';
import { useProductClickHandler } from './useProductClickHandler';

export const useIPaperInit = (iframeLoaded: boolean) => {
  const localLogger = useLogger('useIPaperInit');
  const onProductAdded = useProductClickHandler();
  const { setCurrentPages } = usePageNumberContext();
  const { catalogueUrl } = useCatalogueUrlAndId();
  const [initialized, setInitialized] = useState(false);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const setScriptLoadedTrue = useCallback(() => setScriptLoaded(true), []);

  const init = useCallback(() => {
    const iPaperApiInstance = getApiInstance();
    if (iPaperApiInstance && !initialized) {
      setInitialized(true);
      // Was in original implementation
      iPaperApiInstance.updateConfig({ preventAction: { basketClick: true } });
      // When bag icon is clicked, add to card, open PID or open shade picker
      void iPaperApiInstance.basket.onProductAdded(onProductAdded);

      iPaperApiInstance.paging.onChange((data: OnPageChange) => {
        setCurrentPages(data.currentSpread);
      });

      window.addEventListener('popstate', () => {
        iPaperApiInstance.paging.goToPage(getPageNumberParamFromUrl());
      });
      localLogger.debug('iPaperJsApi initialized');
    }
  }, [initialized, localLogger, onProductAdded, setCurrentPages]);

  useEffect(() => {
    let script: HTMLElement | null = null;
    if (catalogueUrl) {
      script = loadIPaperAPIScript();
      script?.addEventListener('load', setScriptLoadedTrue);
    }

    return () => {
      script?.removeEventListener('load', setScriptLoadedTrue);
    };
  }, [catalogueUrl, setScriptLoadedTrue]);

  useEffect(() => {
    // If script and iframe are loaded, we can initialize iPaperApiInstance.
    if (scriptLoaded && iframeLoaded) {
      init();
      // If rerender happens and iframe is again not loaded, we need to get a new initialization.
    } else if (!iframeLoaded) {
      setInitialized(false);
    }
  }, [iframeLoaded, init, scriptLoaded]);
};
