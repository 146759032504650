// istanbul ignore file -- No need to test passing of data to context.
import type { FC, PropsWithChildren } from 'react';
import { createContext, useContext } from 'react';

import { useGetCatalogueLandingPageData } from '../api';
import type { CataloguesLandingPage } from '../models';

export interface CataloguesLandingPageContext {
  data: CataloguesLandingPage | null;
  loading: boolean;
}

const defaultCataloguesLandingPageContextValues: CataloguesLandingPageContext = {
  data: null,
  loading: true,
};

export const cataloguesLandingPageContext = createContext<CataloguesLandingPageContext>(
  defaultCataloguesLandingPageContextValues,
);

export const { Provider } = cataloguesLandingPageContext;

export const CataloguesLandingPageProvider: FC<PropsWithChildren> = ({ children }) => {
  const value = useGetCatalogueLandingPageData();

  return <Provider value={value}>{children}</Provider>;
};

export const useCataloguesLandingPageContext = () => useContext(cataloguesLandingPageContext);
