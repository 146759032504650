import { useContext } from 'react';

import { PageNumberContext } from '../contexts';
import type { PageNumberContextValues } from '../models';

export const usePageNumberContext = (): PageNumberContextValues => {
  const context = useContext(PageNumberContext);

  if (context) {
    return context;
  }

  throw new Error('PageNumberContext value must be set.');
};
