import { mainLogger } from '../logger';

export type ImageType = 'Normal' | 'Thumb' | 'Zoom';

export const generateImageUrls = (catalogueUrl: string, totalPageCount: number, imageType: ImageType = 'Thumb') => {
  const localLogger = mainLogger.createChild('generateImageUrls');
  try {
    const imageUrl = new URL('Image.ashx', catalogueUrl.replace(/\/?$/, '/'));

    return Array.from({ length: totalPageCount }).map((_, index) => {
      const pageNumber = index + 1;

      imageUrl.searchParams.set('PageNumber', pageNumber.toString());
      imageUrl.searchParams.set('ImageType', imageType);

      return {
        imageUrl: imageUrl.toString(),
        pageNumber,
      };
    });
  } catch (error: unknown) {
    localLogger.warn('Failed to generate image URLs', { error: JSON.stringify(error) });

    return [];
  }
};
