import { CATALOGUE_DEFAULT_PAGE, CATALOGUE_MAX_PAGE } from '../../constants';

export const transformPageInput = (input: number | string, maxPage = CATALOGUE_MAX_PAGE) => {
  const page = typeof input === 'string' ? Number.parseInt(input, 10) : Math.trunc(input);

  if (Number.isNaN(page) || page < CATALOGUE_DEFAULT_PAGE) {
    return CATALOGUE_DEFAULT_PAGE;
  }

  if (page > maxPage) {
    return maxPage;
  }

  return page;
};
