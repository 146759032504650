import { styled } from '@ori-ui/mui';

export const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  [theme.breakpoints.up('sm')]: {
    gap: theme.spacing(6),
  },
}));

export const Inner = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  maxWidth: '100%',
  [theme.breakpoints.up('sm')]: {
    gap: theme.spacing(3),
    maxWidth: 400,
  },
}));
